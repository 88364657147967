import React, { Component } from "react";


import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import ReactMarkdown from 'react-markdown'


import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`
{
 
  strapiAvisoLegal(locale: {eq: "en"})  {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    declaracion
  }
}
`


class Legalnotification extends Component {

  render() {


    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <Banner banner={data.strapiAvisoLegal.Banner} />
              <section className="padding-section-int">
                <div className="container">
                  <div typeof="foaf:Document">
                    <span property="dc:title" content="Legal Notification" className="rdf-meta element-hidden"></span>
                    <div className="content clearfix">
                      <div className="row">
                        <div className="col-md-8 offset-md-2" style={{textAlign:"justify"}}>
                        <ReactMarkdown>{data.strapiAvisoLegal.declaracion}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default Legalnotification
