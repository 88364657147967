import * as React from "react"
import appInsights from '../../../../AppInsights';
import Legalnotification from "../../../route/Legalnotification_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Legalnotification props={props} />
      </React.Fragment>
  )
}

export default IndexPage
